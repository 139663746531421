// @ts-nocheck
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { injectIntl } from 'gatsby-plugin-intl';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'animate.css/animate.min.css';
import {
  Row, Form, Col, Button,
} from 'react-bootstrap';

import styled from 'styled-components';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Title from '../../components/Title';
import heroImage from '../../images/contact-us-background.jpg';
import Footer from '../../components/Footer';
import SubmitButton from '../../components/SubmitButton';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContactUsOtherPage = () => {
  const [formValues, setFormValues] = useState({
    contactName: '',
    contactEmail: '',
    type: 'Education Agent',
    phoneNumber: '',
    enquiry: '',
    gotcha: '',
  });

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post('https://formspree.io/xrknwjvm', {
        contactName: formValues.contactName,
        contactEmail: formValues.contactEmail,
        type: formValues.type,
        phoneNumber: formValues.phoneNumber,
        enquiry: formValues.enquiry,
        contactAccepted: true,
        privacyPolicyAccepted: true,
        _gotcha: formValues.gotcha,
        _replyto: formValues.contactEmail,
        _cc: 'info@homestaynetwork.org',
      })
      .then(() => {
        setFormValues({
          contactName: '',
          contactEmail: '',
          type: 'Education Agent',
          phoneNumber: '',
          enquiry: '',
          gotcha: '',
        });
        toast.success('Success! Your enquiry was submitted successfully!');
      });
  };

  return (
    <>
      <Helmet />
      <Layout>
        <SEO title="Contact Us" />
        <Wrapper className="container-fluid">
          <Title imageUrl={heroImage} position="top" text="Contact Us - Agents & Other" centered />
          <Row className="pt-5">
            <Col lg={5} md={8} sm={12} className="mx-auto">
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Control
                  type="text"
                  name="gotcha"
                  style={{ display: 'none' }}
                  value={formValues.gotcha}
                  onChange={handleChange}
                />
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      id="contactName"
                      name="contactName"
                      onChange={handleChange}
                      value={formValues.contactName}
                      type="text"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      id="contactEmail"
                      name="contactEmail"
                      onChange={handleChange}
                      value={formValues.contactEmail}
                      type="email"
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      id="phone"
                      name="phoneNumber"
                      onChange={handleChange}
                      value={formValues.phoneNumber}
                      type="text"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12}>
                    <Form.Label>I am a</Form.Label>
                    <Form.Control
                      as="select"
                      id="type"
                      name="type"
                      onChange={handleChange}
                      value={formValues.type}
                      required
                    >
                      <option>Education Agent</option>
                      <option>Education Institution</option>
                      <option>Host</option>
                      <option>Media</option>
                      <option>Other</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Group>
                  <Form.Label>Enquiry</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    name="enquiry"
                    value={formValues.enquiry}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="I agree with the MyStay English Privacy Policy which can be found at the footer of the page. *"
                    required
                  />
                  <Form.Check
                    type="checkbox"
                    label="MyStay English can contact me by email or phone with related information."
                    required
                  />
                </Form.Group>
                <SubmitButton variant="primary" type="submit">
                  Submit
                </SubmitButton>
              </Form>
            </Col>
          </Row>
          <Footer />
        </Wrapper>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default injectIntl(ContactUsOtherPage);
